import React, { useLayoutEffect, useRef, useState } from 'react';
import { WebView } from 'react-native-webview';
import { EXPO_PUBLIC_ALLOWED_ORIGINS, EXPO_PUBLIC_APP_ENV } from '@env';
import { StatusBar } from 'expo-status-bar';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View, Pressable } from 'react-native';
import { EXTERNAL_URLS } from '../resources/constants';
import { isWeb } from '../utils/responsive';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { StackHeader } from '../components';

const redirectTo = isWeb ? window.location.href : '';
const loginPage = EXPO_PUBLIC_APP_ENV === 'dev' ? 'login' : 'login';
export const loginUrl = `${EXTERNAL_URLS.login}/${loginPage}?redirectTo=${redirectTo}`;
//export const loginUrl = `${'https://iam.data-stage.mome.hu'}/${loginPage}?redirectTo=${redirectTo}`;

interface Props {
  children: React.ReactNode;
  onLogin: () => void;
}

const AuthGuard: React.FC<Props> = ({ children, onLogin }) => {
  const { auth, setAuth } = useAuth();
  const insets = useSafeAreaInsets();
  const [returnValue, setReturnValue] = useState(null);
  const webViewRef = useRef(null);
  const [canGoBack, setCanGoBack] = useState(false);

  const onWebViewMessage = async (event) => {
    const loginData = JSON.parse(event.nativeEvent.data);

    if (loginData) {
      setAuth(loginData);
      onLogin();
    }
  };

  function handleBackPress() {
    if (webViewRef.current) {
      webViewRef.current.goBack();
      return true;
    }
    return false;
  }

  function handleNavigationStateChange(navState) {
    setCanGoBack(navState.canGoBack);
  }

  const loginWebView = (
    <View
      style={{
        paddingTop: insets.top,
        paddingBottom: insets.bottom,
      }}
      className="flex-1 bg-neutral-950"
    >
      <StatusBar hidden={true} />
      {canGoBack && <StackHeader loginScreen={true} onPress={handleBackPress} />}
      <WebView
        ref={webViewRef}
        source={{ uri: loginUrl }}
        onMessage={onWebViewMessage}
        originWhitelist={EXPO_PUBLIC_ALLOWED_ORIGINS.split(',')}
        javaScriptEnabled={true}
        onNavigationStateChange={handleNavigationStateChange}
      />
    </View>
  );

  const checkAuth = () => {
    if (auth?.user) {
      setReturnValue(children);
    } else if (isWeb) {
      window.location.href = loginUrl;
    } else {
      setReturnValue(loginWebView);
    }
  };

  useLayoutEffect(() => {
    const authFetched = typeof auth !== 'undefined';

    if (authFetched && EXPO_PUBLIC_APP_ENV !== 'dev') {
      checkAuth();
    }
  }, [auth, children]);

  return EXPO_PUBLIC_APP_ENV === 'dev' ? children : returnValue;
  //return loginWebView;
};

export default AuthGuard;
