import React from 'react';
import { Pressable, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { RouteProp } from '@react-navigation/native';
import Layout from '../../components/Layout/index';
import { NavigationParamList } from '../../navigation/types';
import { ErrorType } from '../../resources/interfaces';
import { adaptiveContainerPaddingTop, isMobile, navHeight } from '../../utils/responsive';
import { handleLinkPress } from '../../utils';
import { Icon } from '../../components';
import svgIcons from '../../assets';

interface Props {
  error?: Error;
  resetErrorBoundary?: () => void;
  route?: RouteProp<NavigationParamList>;
}

const ErrorScreen: React.FC<Props> = ({ route, error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let type = route?.params?.type;

  if (!type) {
    type = route?.name === '*' ? ErrorType.not_found : ErrorType.internal_server_error;
  }

  const translations = {
    not_found: {
      code: 404,
      main: t('error_messages:404_page_not_found'),
      secondary: t('error_messages:404_check_url'),
    },
    internal_server_error: {
      code: 500,
      main: t('error_messages:500_wrong_on_our_end'),
      secondary: t('error_messages:500_try_later'),
    },
  };

  return (
    <Layout isScrollView={false} layoutWithoutMenu={!!error}>
      <View style={{ marginTop: isMobile ? navHeight + adaptiveContainerPaddingTop : 0 }}>
        <Text className="text-primary-600 dark:text-primary-500 font-[sans-700] text-articleTitleMobile md:text-errorTitle">
          {translations[type]?.code}
        </Text>
        <Text
          className="text-neutral-930 dark:text-neutral-50 font-[sans-700] text-articleTitleMobile md:text-errorTitle"
          style={{ marginBottom: isMobile ? 8 : 16 }}
        >
          {translations[type]?.main}
        </Text>
        <Text className="text-neutral-930 dark:text-neutral-50 font-[sans-600] text-errorSubtitleMobile md:text-errorSubtitle">
          {translations[type]?.secondary}
        </Text>
        {type !== ErrorType.not_found && (
          <View className="flex-row items-center mt-6 md:mt-10">
            <Pressable
              onPress={() =>
                handleLinkPress(
                  'https://forms.clickup.com/24417130/f/q94va-13865/UF1ANHWVMM7PIEJNYD',
                )
              }
              className="flex-row items-center"
            >
              <Icon
                icon={svgIcons.bugIcon}
                webSize={{ width: 24, height: 24 }}
                mobileSize={{ width: 16, height: 16 }}
                classNames="mr-3"
              />
              <Text className="text-xs md:text-body font-[sans-400] text-neutral-950 dark:text-neutral-50 underline">
                {t('common:bug_report')}
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </Layout>
  );
};

export default ErrorScreen;
