import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenName, StackName } from '../types';
import { PersonalizationScreen, SettingsScreen } from '../../screens';
import { isMobile } from '../../utils/responsive';
import { StackHeader } from '../../components';

const SettingsStack = createStackNavigator();

export default function SettingsStackNavigator() {
  const { t } = useTranslation();
  return (
    <SettingsStack.Navigator screenOptions={{ gestureEnabled: true, headerShown: false }}>
      <SettingsStack.Screen
        name={ScreenName.ListScreen}
        component={SettingsScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return <StackHeader navigation={navigation} headerTitle={t('section:settings')} />;
            },
            title: t('section:settings'),
          };
        }}
      />
      <SettingsStack.Screen
        name={ScreenName.PersonalizationScreen}
        component={PersonalizationScreen}
        options={({ navigation }) => {
          return {
            gestureEnabled: true,
            headerShown: isMobile,
            header: () => {
              return (
                <StackHeader navigation={navigation} headerTitle={t('section:customization')} />
              );
            },
            title: t('section:customization'),
          };
        }}
      />
    </SettingsStack.Navigator>
  );
}
