import { useColorScheme } from 'nativewind';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform, Pressable, Text, View } from 'react-native';
import { BlurView } from 'expo-blur';
import { FC } from 'react';
import { NativeStackNavigationProp } from 'react-native-screens/native-stack';
import {
  isMobile,
  isMobileWeb,
  isNative,
  isTablet,
  isWeb,
  navHeight,
} from '../../utils/responsive';
import { Icon } from '../index';
import svgIcons from '../../assets';
import { NavigationParamList, ScreenName } from '../../navigation/types';

interface Props {
  headerTitle?: string;
  navigation?: NativeStackNavigationProp<NavigationParamList>;
  fromScreen?: ScreenName;
  loginScreen?: boolean;
  onPress?: () => void;
}

const StackHeader: FC<Props> = ({ headerTitle, navigation, fromScreen, loginScreen, onPress }) => {
  const { colorScheme } = useColorScheme();

  //To get the height of the statusbar = insets.top
  const insets = useSafeAreaInsets();
  const navOpacity = Platform.OS === 'android' || fromScreen ? 0.9 : 0.75;

  return (
    <BlurView
      style={[
        {
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: isMobileWeb ? 'center' : 'flex-end',
          justifyContent: isMobile ? 'space-between' : 'center',
          position: isNative ? 'absolute' : ('fixed' as 'absolute'),
          flex: 1,
          zIndex: 100,
          width: '100%',
          //On mobile we add the insets.top so the header is fully visible, this is instead of safeAreaView
          paddingTop: insets.top,
          paddingHorizontal: 12,
          backgroundColor:
            colorScheme === 'dark'
              ? `rgba(16,16,16,${navOpacity})`
              : `rgba(246,246,246,${navOpacity})`,
        }, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isWeb ? { transition: 'background-color 0.3s ease-in-out' } : null,
      ]}
      intensity={30}
      tint={colorScheme}
    >
      <View className="w-full items-center flex-row  justify-between" style={{ height: navHeight }}>
        <View className="flex-row items-center">
          <Pressable
            onPress={() => (!loginScreen ? navigation.goBack() : onPress())}
            className="py-1"
          >
            <Icon
              icon={svgIcons.arrowLeftIcon}
              mobileSize={{ width: 28, height: 28 }}
              webSize={{ width: 24, height: 24 }}
              classNames={'mr-4'}
            />
          </Pressable>

          {headerTitle && (
            <Text className="text-neutral-930 dark:text-neutral-50 text-articleTitleMobile font-[sans-400]">
              {headerTitle}
            </Text>
          )}
        </View>
        {fromScreen && !isTablet && (
          <Pressable
            onPress={() =>
              navigation.navigate(ScreenName.PostFilterScreen, { fromScreen: fromScreen })
            }
            className="pl-4 py-2"
          >
            <Icon
              icon={svgIcons.searchIcon}
              mobileSize={{ width: 24, height: 24 }}
              webSize={{ width: 24, height: 24 }}
            />
          </Pressable>
        )}
      </View>
    </BlurView>
  );
};

export default StackHeader;
