import React, { useCallback, useContext } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { EXPO_PUBLIC_APP_DOMAIN } from '@env';
import { useFocusEffect } from '@react-navigation/core';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import LanguageChanger from '../../components/LanguageChanger';
import ThemeChanger from '../../components/ThemeChanger';
import Layout from '../../components/Layout/index';
import { NavItem } from '../../components';
import svgIcons from '../../assets';
import { handleLinkPress } from '../../utils';
import {
  NavigationParamList,
  ScreenName,
  StackNavigationScreenProps,
} from '../../navigation/types';
import { adaptiveContainerPaddingTop, isMobile, navHeight } from '../../utils/responsive';
import { cln } from '../../utils/classnames';
import { NavigationContextStack } from '../../contexts/NavigationContext/NavigationContextStack';

const SettingsScreen: React.FC<
  StackNavigationScreenProps<NavigationParamList, ScreenName.SettingsScreen>
> = ({ navigation, route }) => {
  const { t, i18n } = useTranslation();

  const { setStackNavigation, setStackRoute } = useContext(NavigationContextStack);

  useFocusEffect(
    useCallback(() => {
      setStackRoute(route);
      setStackNavigation(navigation);
    }, []),
  );

  const handlePrivacyPolicyPress = () => {
    handleLinkPress(`${EXPO_PUBLIC_APP_DOMAIN}/privacy/privacy_${i18n.language}.html`);
  };
  const handleFeedbackPress = () => {
    handleLinkPress('https://forms.clickup.com/24417130/f/q94va-13865/UF1ANHWVMM7PIEJNYD');
  };

  const insets = useSafeAreaInsets();

  return (
    <Layout isScrollView={false} title={!isMobile && t('section:settings')}>
      <View
        style={{ paddingTop: insets.top + navHeight + adaptiveContainerPaddingTop }}
        className={cln('flex-column', isMobile ? 'gap-y-10' : 'gap-y-20')}
      >
        <View className="md:flex-row md:w-full flex-column w-full">
          <LanguageChanger />
          <ThemeChanger />
        </View>
        <View className="flex-column">
          <NavItem
            onPress={handlePrivacyPolicyPress}
            title={t('common:privacy')}
            webSubtitle={t('common:privacy_policy')}
            selectedIcon={svgIcons.lockIcon}
          />
          <NavItem
            onPress={handleFeedbackPress}
            title={t('common:feedback')}
            webSubtitle={t('common:bug_report')}
            selectedIcon={svgIcons.bugIcon}
          />
          <NavItem
            onPress={() => navigation.navigate(ScreenName.PersonalizationScreen)}
            title={t('section:customization')}
            isNavigation={true}
          />
        </View>
      </View>
    </Layout>
  );
};

export default SettingsScreen;
