import React, { ReactNode } from 'react';
import { View, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { cln } from '../../utils/classnames';
import { isMobile, isNative, navHeight } from '../../utils/responsive';

interface Props {
  isScrollView?: boolean;
  children: ReactNode;
  refProp?: any;
  layoutWithoutMenu?: boolean;
  isArticleStyle?: boolean;
  verticalPadding: number;
  isHomeScreen?: boolean;
  isListScreen?: boolean;
}

const AdaptiveContainer: React.FC<Props> = ({
  isScrollView,
  children,
  refProp,
  verticalPadding,
  isHomeScreen,
  isListScreen,
}) => {
  const insets = useSafeAreaInsets();

  const startBelowNavbar = insets.top + 50;

  // SCROLLVIEW
  return isScrollView ? (
    <ScrollView
      overScrollMode="never"
      contentContainerStyle={{
        paddingHorizontal: 30,
        paddingTop: startBelowNavbar + verticalPadding,
        paddingBottom: verticalPadding,
        width: '100%',
        alignSelf: 'center',
      }}
      ref={refProp}
      scrollIndicatorInsets={{ left: -10 }}
    >
      {children}
    </ScrollView>
  ) : (
    <View
      className={cln(
        isMobile && !isListScreen
          ? 'px-[30px]'
          : isMobile && isListScreen
            ? 'px-0'
            : 'px-8 md:px-20 md:pt-20',
        'w-full',
      )}
      style={{
        flex: 1,
        position: 'relative',
      }}
      ref={refProp}
    >
      {children}
    </View>
  );
};

export default AdaptiveContainer;
